.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    background-image: url('../../../public/img/5.PopUp/logoBig.webp'), url('../../../public/img/5.PopUp/LightPopUp.png');
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto;
    padding-top: 70px;
}

.popup-preregistered {
    background-image: url('../../../public/img/5.PopUp/logoBig.webp'),
        url('../../../public/img/5.PopUp/LightPopUp.png'),
        url('../../../public/img/5.PopUp/bg_ashara_coins.webp');
    background-position: center 90%, center center, center center;
    background-size: auto, contain, 60%;
}


.popup-overlay .close-button {
    background: none;
    border: none;
    font-size: 32px;
    color: white;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 10%;
    right: 10%;
}

.popup-overlay .close-button:hover {
    filter: drop-shadow(0px 0px 50px rgba(0, 224, 255, 1));
    color: rgba(0, 224, 255, 1);

}

.popup-overlay .popup-logo {
    width: 215px;
    margin: 0 auto;

}

.popup-overlay .popup-body {
    text-align: center;

}

.popup-overlay .popup-body hr {
    height: 1px;
    background: var(--color-hr-secundary);
    border: none;
    margin-top: -5px;
    width: 70%;
    margin: 0 auto;
    padding: 0;
}

.popup-overlay .popup-body h2 {
    font-family: var(--font-bebas);
    font-weight: bold;
    font-size: 60px;
    margin: 50px 0 -10px;
    padding: 0;
    text-align: center;
}

.popup-overlay .popup-body .sub-tittle {
    font-family: var(--font-raleway-black);
    font-size: 48px;
    color: var(--color-text-important);
    padding: 0;
    margin: 60px 0 0;
    text-align: center;

}

.popup-overlay .popup-body .sub-tittle2 {
    font-family: var(--font-raleway-bold);
    font-size: 32px;
    text-align: center;
    padding: 0;
    margin: -10px 0 0;
}

.popup-overlay .popup-body .sub-tittle10k {
    font-family: var(--font-montserrat-black-italic);
    text-transform: uppercase;
    text-align: center;
    font-size: 136px;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    background-image: linear-gradient(180deg, rgba(255, 199, 0, 0) 50%, rgba(255, 199, 0, 0.22) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 0 3px rgba(255, 199, 0, 1)) drop-shadow(0 0 3px rgba(255, 199, 0, 1))
}

.popup-overlay .popup-body .txt-PopUp {
    font-family: var(--font-montserrat-bold-italic);
    font-size: 34px;
    text-align: center;
    padding: 0;
    margin: -30px 0 0;
}



@media only screen and (max-width: 767.9px) {}

@media only screen and (min-width: 768px) {
    /*TO DO*/
    .popup-overlay {
        padding-top: 70px;
    }

    .popup-preregistered {
        background-image: url('../../../public/img/5.PopUp/logoBig.webp'),
            url('../../../public/img/5.PopUp/LightPopUp.png'),
            url('../../../public/img/5.PopUp/bg_ashara_coins.webp');
        background-position: center 90%, center center, center center;
        background-size: auto, contain, 84%;
    }


    .popup-overlay .close-button {
        font-size: 2rem;
    }

    .popup-overlay .popup-logo {
        width: 215px;
    }

    .popup-overlay .popup-body hr {
        height: 2px;
        width: 100%;
    }

    .popup-overlay .popup-body h2 {
        font-size: 3.75rem;
        margin: 50px 0 -10px;
    }

    .popup-overlay .popup-body .sub-tittle {
        font-size: 3rem;
        margin: 60px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle2 {
        font-size: 2rem;
        margin: -10px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle10k {
        font-size: 8.5rem;
    }

    .popup-overlay .popup-body .txt-PopUp {
        font-size: 2.125rem;
        margin: -30px 0 0;
    }

}

@media only screen and (min-width: 992px) {
    .popup-overlay {
        padding-top: 50px;
    }

    .popup-preregistered {
        background-position: center 91%, center center, center center;
        background-size: 60%, contain, 84%;
    }

    .popup-overlay .close-button {
        font-size: 1.5rem;
        top: 8%;
        right: 8%;
    }

    .popup-overlay .popup-logo {
        width: 150px;
    }

    .popup-overlay .popup-body hr {
        height: 2px;
        width: 90%;
    }

    .popup-overlay .popup-body h2 {
        font-size: 2.5rem;
        margin: 30px 0 -8px;
    }

    .popup-overlay .popup-body .sub-tittle {
        font-size: 2.2rem;
        margin: 40px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle2 {
        font-size: 1.6rem;
        margin: -10px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle10k {
        font-size: 7.5rem;
    }

    .popup-overlay .popup-body .txt-PopUp {
        font-size: 1.8rem;
        margin: -20px 0 0;
    }
}

@media only screen and (min-width: 1200px) {
    .popup-overlay {
        padding-top: 70px;
    }

    .popup-preregistered {
        background-position: center 90%, center center, center center;
        background-size: auto, contain, 60%;
    }

    .popup-overlay .close-button {
        font-size: 2rem;
        top: 10%;
        right: 10%;
    }

    .popup-overlay .popup-logo {
        width: 200px;
    }

    .popup-overlay .popup-body hr {
        height: 2px;
        width: 100%;
    }

    .popup-overlay .popup-body h2 {
        font-size: 3.75rem;
        margin: 40px 0 -10px;
    }

    .popup-overlay .popup-body .sub-tittle {
        font-size: 3rem;
        margin: 50px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle2 {
        font-size: 2rem;
        margin: -10px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle10k {
        font-size: 8.5rem;
    }

    .popup-overlay .popup-body .txt-PopUp {
        font-size: 2.125rem;
        margin: -30px 0 0;
    }
}

@media only screen and (min-width: 2048px) {

    .popup-overlay {
        padding-top: 130px;
    }

    .popup-preregistered {
        background-size: auto, contain, 69%;
    }

    .popup-overlay .close-button {
        font-size: 4rem;
    }


    .popup-overlay .popup-logo {
        width: 320px;
    }

    .popup-overlay .popup-body h2 {
        font-size: 5.7rem;
        margin: 50px 0 -10px;
    }

    .popup-overlay .popup-body .sub-tittle {
        font-size: 6.5rem;
        margin-top: 70px;
    }

    .popup-overlay .popup-body .sub-tittle2 {
        font-size: 3.7rem;
        margin: -25px 0 0;
    }

    .popup-overlay .popup-body .sub-tittle10k {
        font-size: 18rem;
    }

    .popup-overlay .popup-body .txt-PopUp {
        font-size: 3.125rem;
        margin: -50px 0 0;
    }

}