.containerPageNotFound {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    align-content: start;
    justify-items: center;
    margin: 50px 0 0;
}

@keyframes fadeOpacity {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.5;
    }
}

.backgroundPageNotFound {
    position: absolute;
    width: auto;
    height: auto;
    display: block;
    bottom: 0;
    opacity: 0.5;
    animation: fadeOpacity 4s infinite ease-in-out;
}

.pageNotFound {
    display: grid;
    align-content: center;
    justify-items: center;
    position: relative;
}

.pageNotFound h2 {
    font-family: var(--font-raleway-extrabold);
    font-size: 30px;
    color: #dadada;
    margin: 75px 0;
}

.pageNotFound img {
    width: 30%;
    margin-left: -50px;
    margin-top: 50px;
}