article {
  position: relative;
}

article.container-quest h3 {
  font-family: var(--font-bebas);
  font-size: 28px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

article.container-quest .container-quest2 {
  position: relative;
  border-radius: 11px;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 2px 5fr;
  justify-content: center;
  align-items: start;
}

.imgMision {
  grid-row: 2;
  grid-column: 1 / 4;
  width: 60%;
  margin: 20px auto;
  border-radius: 11px;

}

.Disabled {
  filter: grayscale(100%);
}

article.container-quest .container-quest2::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 11px;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.todo {
  box-shadow: 0px 0px 26px rgba(0, 209, 255, 0.33);
  background: rgb(0, 0, 0);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 18, 32, 1) 100%);
}

.todo::before {
  background: linear-gradient(to bottom,
      rgba(59, 207, 240, 1) 0%,
      rgba(0, 209, 255, 0) 81%);
}

.in-review {
  box-shadow: 0px 0px 26px rgba(240, 102, 59, 0.33);
  background: rgb(0, 0, 0);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(32, 8, 0, 1) 100%);
}

.in-review::before {
  background: linear-gradient(to bottom,
      rgba(240, 102, 59, 1) 0%,
      rgba(240, 102, 59, 0) 81%);
}

.not-approved {
  box-shadow: 0px 0px 26px rgba(255, 0, 0, 0.33);
  background: rgb(0, 0, 0);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(32, 0, 0, 1) 100%);
}

.not-approved::before {
  background: linear-gradient(to bottom,
      rgba(240, 59, 59, 1) 0%,
      rgba(255, 0, 0, 0) 81%);
}

.completed {
  box-shadow: 0px 0px 26px rgba(57, 255, 113, 0.33);
  background: rgb(0, 0, 0);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 32, 22, 1) 100%);
}

.completed::before {
  background: linear-gradient(to bottom,
      rgba(12, 154, 0, 1) 0%,
      rgba(0, 255, 26, 0) 81%);
}

.container-questGame {
  width: 100%;
  margin-top: 11px;
  text-align: center;
  letter-spacing: 0.1em;

  & a {
    text-decoration: none;

    & img {
      width: fit-content;
    }

    & p {
      margin: 0;
      font-family: var(--font-bebas);
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

}

.vertical-separator {
  height: 152px;
  width: 1px;
  opacity: 0.35;
}

.vs-todo {
  background: linear-gradient(180deg,
      rgba(0, 224, 255, 0) 0%,
      rgba(0, 224, 255, 1) 30%,
      rgba(0, 224, 255, 0.89) 51%,
      rgba(0, 224, 255, 0) 100%);
}

.vs-in-review {
  background: linear-gradient(180deg,
      rgba(240, 102, 59, 0) 0%,
      rgba(240, 102, 59, 1) 30%,
      rgba(240, 102, 59, 0.89) 51%,
      rgba(240, 102, 59, 0) 100%);
}

.vs-not-approved {
  background: linear-gradient(180deg,
      rgba(255, 0, 0, 0) 0%,
      rgba(255, 0, 0, 1) 30%,
      rgba(255, 0, 0, 0.89) 51%,
      rgba(255, 0, 0, 0) 100%);
}

.vs-completed {
  background: linear-gradient(180deg,
      rgba(57, 255, 113, 0) 0%,
      rgba(57, 255, 113, 1) 30%,
      rgba(57, 255, 113, 0.89) 51%,
      rgba(57, 255, 113, 0) 100%);
}

.container-questInfo {
  width: 95%;
  margin: 11px 17px;
}

.container-questInfo h4 {
  font-family: var(--font-raleway-bold);
  font-size: 18px;
  margin: 0;
}

.container-questInfo p {
  font-family: var(--font-raleway-italic);
  font-size: 13px;
  margin: 9px 0;
}

.container-questInfo p.text-completed {
  max-width: 500px;
  word-wrap: break-word;
  color: #39ff71;
}

.container-questInfo p.text {
  color: #00e0ff;
}

.container-questInfo .container-deadline {
  display: flex;
  column-gap: 11px;
  align-items: center;
}

.container-questInfo .container-deadline img {
  height: fit-content;
}

.container-questInfo .container-deadline img.deadline-completed {
  filter: drop-shadow(0px 0px 5.8px rgba(57, 255, 113, 0.75));
}

.container-questInfo .container-deadline img.deadline {
  filter: drop-shadow(0px 0px 5.8px rgba(255, 152, 57, 0.75));
}

.container-deadline p {
  font-family: var(--font-raleway-light-italic);
  font-size: 13px;
}

.container-deadline p span {
  font-family: var(--font-raleway-semibold-italic);
}

.txt-reward {
  display: flex;
  align-items: center;
}

.txt-reward p {
  font-family: var(--font-raleway-bold);
  color: #ffb039;
  font-size: 16px;
}

.txt-reward img {
  width: auto;
}

.txt-reward span {
  font-family: var(--font-raleway-bold);
  color: #00ffe0;
  font-size: 16px;
}

.txt-reward a {
  font-family: var(--font-raleway-semibold-italic);
  color: #00B2FF;
  font-size: 13px;
  width: 28em;
  word-wrap: break-word;
  margin-left: 10px;
}

.txt-reward a:hover {
  color: #00ffe0;
}

.txt-reward .quest-medal {
  height: 38px;
  width: 38px;
  margin: 6px;
  clip-path: circle(50%);
  filter: drop-shadow(0px 0px 16px rgba(0, 178, 255, 1));
}

.txt-reward .quest-img {
  height: 45px;
  width: 45px;
}

.container-questInfo .txt-in-review {
  font-family: var(--font-bebas);
  font-size: 22px;
  font-weight: bold;
  color: #f0663b;
  letter-spacing: 1px;
  margin: 0;
}

.container-questInfo .txt-disabled {
  font-family: var(--font-bebas);
  font-size: 22px;
  font-weight: bold;
  color: #f0663b;
  letter-spacing: 1px;
  margin: 0;
}

.container-questInfo .txt-completed {
  font-family: var(--font-bebas);
  font-size: 36px;
  font-weight: bold;
  color: #39ff71;
  letter-spacing: 1px;
  margin: 0 0 -10px 0;
}

.container-questInfo .txt-no-appoved {
  font-family: var(--font-bebas);
  font-size: 18px;
  font-weight: bold;
  color: #ff3d3d;
  letter-spacing: 1px;
  margin: 0;
}

.container-questInfo .txt-why-no-appoved {
  max-width: 500px;
  word-wrap: break-word;
  font-family: var(--font-raleway-italic);
  font-size: 13px;
  color: #ff5454;
}

.container-questInfo .container-todo,
.container-questInfo .container-not-approved,
.container-questInfo .container-completed {
  display: flex;
  gap: 0px 10px;
  margin: 0;
}

.container-questInfo .container-todo input {
  width: 65%;
  height: 25px;
  border-radius: 8px;
  z-index: 0;
  box-shadow: 0px 0px 26px rgba(0, 163, 255, 0.33);
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 28, 32, 1) 100%);
  border: 1px solid rgba(59, 197, 240, 1);
  border-top: 0px;
  font-family: var(--font-raleway-italic);
  font-size: 13px;
  color: #fff;
}

.container-questInfo .container-completed input {
  width: 65%;
  height: 25px;
  border-radius: 8px;
  z-index: 0;
  box-shadow: 0px 0px 26px rgba(57, 255, 113, 0.33);
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 28, 32, 1) 100%);
  border: 1px solid rgba(12, 154, 0, 1);
  border-top: 0px;
  font-family: var(--font-raleway-italic);
  font-size: 13px;
  color: #fff;
}

.container-questInfo .container-not-approved input {
  width: 65%;
  height: 25px;
  border-radius: 8px;
  z-index: 0;
  box-shadow: 0px 0px 26px rgba(255, 61, 61, 0.33);
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 28, 32, 1) 100%);
  border: 1px solid rgba(255, 61, 61, 1);
  border-top: 0px;
  font-family: var(--font-raleway-italic);
  font-size: 13px;
  color: #fff;
}

.container-questPartner:hover {
  box-shadow: -8px 8px 20px rgba(255, 199, 0, 0.15);
}

.container-questPartner {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(32, 15, 0, 1) 100%);
  height: 60px;
  width: 132px;
  margin: 0;
  box-shadow: -2px 2px 7.1px rgba(255, 199, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 9fr;

  & .container-txtPartner {
    transform: rotate(270deg) translate(-100%, 20%);
    margin: 0;
    padding: 0;

    & .txt-partner {
      color: rgba(255, 199, 0, 1);
      background-color: transparent;
      box-shadow: 0px 0px 11.2px rgba(255, 176, 57, 0.77);
      font-size: 13px;
      width: 0;
      padding: 0;
      font-family: var(--font-bebas);
      letter-spacing: 0;
    }
  }

  & a .txt-partner2 {
    font-family: var(--font-raleway-bold);
    font-size: 10px;
    width: 100%;
    padding: 0;
    letter-spacing: 0;
  }
}

.daily {
  position: absolute;
  width: 100%;
  top: 10px;
  right: -2px;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
}


.continer-Daily {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 5px;
  justify-content: flex-end;
  align-items: center;
  filter: drop-shadow(0px -2px 13.4px rgba(205, 255, 64, 0.46));
  border-radius: 0 6px 0 0;
  width: 12%;
  height: 0px;
  border-top: solid 0px transparent;
  border-right: solid 0px transparent;
  border-bottom: solid 45px rgba(205, 255, 64, 1);
  border-left: solid 55px transparent;
  margin: 0;
}

.continer-Daily p {
  margin: 0;
  padding: 0;
  color: rgba(0, 83, 53, 1);
  font-size: 28px;
  font-family: var(--font-bebas);

}

.continer-Daily2 {
  z-index: 5;
}

.continer-Daily2::before {
  background: linear-gradient(to top,
      rgba(205, 255, 64, 0) 0%,
      rgba(205, 255, 64, 1) 100%);
  padding: 2px !important;
  margin-left: -2px;
}