.container-profile {
    color: white;
    position: relative;
    margin-top: 70px;

    & form {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        width: 50%;
        margin: 0px auto 130px;
        padding: 57px 60px 40px;
        gap: 20px 50px;

        & div {
            width: 90%;
            margin: 0 auto;
            text-align: left;

            & label {
                font-family: var(--font-raleway-semibold);
                font-size: 23px;
                display: block;
            }

            & input {
                width: 100%;
                border-radius: 10px;
                font-family: var(--font-raleway-medium);
                font-size: 20px;
                padding: 5px;
                height: 40px;
                background-color: rgba(255, 255, 255, 0.18);
                border: rgba(0, 78, 112, 1) solid 1px;
                color: white;
            }

            & input:disabled {
                cursor: not-allowed;
                background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
                color: white;
            }

        }

        & .continer-button {
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            text-align: center;
        }
    }

    & form::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg,
                rgba(115, 115, 115, 0) 0%,
                rgba(169, 169, 169, 1) 50%,
                rgba(217, 217, 217, 1) 100%);
        z-index: -1;
        opacity: 0.1;
        border-radius: 10px;
    }

    & .bg-profile {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
    }
}