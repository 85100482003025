footer {
  background-image: url("/public/img/2.Footer/bgSmall.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5% 1%;
  display: grid;
  color: white;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "logo filler filler filler"
    "logo emailme emailme emailme"
    "logo callme callme callme"
    "logo closing closing .";
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.text1 {
  font-family: var(--font-bebas);
  text-transform: uppercase;
  margin: 0.5em;
  letter-spacing: 3px;
}

.text2 {
  font-family: var(--font-raleway-thin);
  color: white;
  text-decoration: none;
  font-size: 0.7em;
  margin: 0 auto;
}


footer .logo {
  grid-area: logo;
  margin: auto;
  width: 70%;
}

.filler {
  grid-area: filler;
  margin: auto;
  height: 32px;
}

.emailme {
  grid-area: emailme;
  display: inline-flex;
  justify-content: flex-end;
}

.callme {
  grid-area: callme;
  display: inline-flex;
  justify-content: flex-end;
}

.closing {
  grid-area: closing;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.privacy {
  display: inline-flex;
  align-items: center;
}

footer .favicon {
  display: none;
}

@media screen and (min-width: 576px) {
  footer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/public/img/2.Footer/bgMedium.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    font-size: 20px;
    padding: 4% 2%;
  }

  .text1 {
    letter-spacing: 3px;
  }

  footer .logo {
    margin: 0;
    width: 87%;
  }
}

@media screen and (min-width: 768px) {
  footer {
    font-size: 25px;
    padding: 4% 3%;
  }

  footer .logo {
    width: 90%;
  }

  .filler {
    padding: 2% 0;
  }

  footer .favicon {
    display: inherit;
    padding-right: 20px;

  }

  footer .policy {
    transition: all 300ms ease 0ms;
  }

  footer .policy:hover {
    transform: rotate(90deg);
  }

}

@media screen and (min-width: 1280px) {
  footer {
    background-image: url("/public/img/2.Footer/bglarge.webp");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    padding: 1% 10%;
  }

  .filler {
    padding: 0;
  }

  .text1 {
    margin: 0.5em;
  }

  footer .logo {
    width: 100%;
  }

  footer .favicon {
    display: inherit;
    padding: 0;
  }
}