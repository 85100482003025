.active .container-listGames {
    box-shadow:
        0px 0px 8.9px 0px rgba(0, 178, 255, 0.6),
        inset 0 0 0 1px rgba(0, 178, 255, 1);
}

.container-listGames {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    border-radius: 4px;
    padding: 10px;
    width: 250px;
}

.container-listGames:hover {
    cursor: pointer;
    box-shadow:
        0px 0px 8.9px 0px rgba(0, 178, 255, 0.25),
        inset 0 0 0 0.5px rgba(0, 178, 255, 1);
}


.container-listGames img {
    width: fit-content;
    height: fit-content
}

.container-listGames .container-listGames__tittle {
    font-family: var(--font-bebas);
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    padding: 0;
}