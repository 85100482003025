.container-inventory {
    color: #fff;
    padding-bottom: 50px;

    & .container-inventorydb {
        width: 55%;
        margin: 0 auto;

    }

    & .container-nft {
        width: 100%;
        position: relative;
        display: inline-block;

        & .bg-btn-profile {
            width: 100%;
        }

        & .container-nft-btn {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & .container-nft-btn2 {
                display: flex;
                width: 40%;
                justify-content: center;
                margin: auto;
            }

            & hr {
                height: 1px;
                background: var(--color-hr-secundary);
                border: none;
                width: 60%;
            }
        }
    } 
}

