.coin-item {
    color: #FFD540;

    & h1 {
        font-family: var(--font-raleway-semibold-italic);
        font-size: 13px;
        text-shadow: 0px 0px 8.2px rgba(255, 199, 0, 0.89);
        margin-bottom: 11px;
    }

    & div {
        position: relative;
        display: flex;
        align-items: center;
        width: 154px;
        height: 31px;
        border-radius: 30px;
        background-color: black;
        box-shadow: 0px 0px 12.6px rgba(255, 199, 0, 0.44);
        text-align: right;

        & img {
            position: absolute;
            left: 2%;
            top: 50%;
            transform: translate(0%, -50%);
            z-index: 2;
        }

        & p {
            position: absolute;
            right: 2%;
            z-index: 2;
            margin-right: 10px;
            text-shadow: 0px 0px 8.2px rgba(255, 199, 0, 0.89);
            font-family: var(--font-raleway-italic);
            font-weight: bold;
            letter-spacing: 1px;
            font-size: 18px;
        }

        & p:hover {
            font-size: 20px;
        }
    }

    & div::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: linear-gradient(101deg, rgba(255, 199, 0, 0) 0%, rgba(128, 100, 0, 1) 100%);
        background-color: rgba(255, 204, 74, 0.25);
        z-index: 1;
        border-radius: 30px;
    }
}