.carousel-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.carousel-slide {
    position: relative;
    height: 100%;
}

.carousel-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    z-index: 0;
}

.carousel-content.active {
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.carousel-text p {
    width: 60%;
    margin: auto;
    font-family: var(--font-raleway-medium);
    color: white;
    font-size: 19px;
    margin-bottom: 60px;
    margin-top: 45px;
}

.carousel-text img{
    width: auto;
    margin: 0;
}

.carousel-indicators {
    position: absolute;
    bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0 40px;
    z-index: 2
}

.indicator {
    width: 20px;
    height: 20px;
    background: #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.indicator:hover {
    background: darkgray;
}

.indicator.active {
    background: #00B2FF;
    filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.6));
}



@media only screen and (max-width: 767.9px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 2048px) {
}