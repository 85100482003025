.container-game {
    justify-self: end;
    display: grid;
    grid-template-columns: 3fr 4fr;
    column-gap: 4%;
    align-items: center;
    height: 630px;
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 0 14px 14px 0;
    box-shadow: 0px 0px 60px 0px var(--drop-shadow)
}


.container-game img {
    justify-self: end;
}

.container-game .container-game__details {
    justify-self: start;
    height: 333px;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.container-game .container-game__details p {
    margin: 0;
    padding: 0 30px 0 0;
}

.container-game .container-game__details .container-game__tittle {
    font-family: var(--font-bebas);
    font-size: 2.6rem;
    text-transform: uppercase;
    font-weight: bold;
}

.container-game .container-game__details .container-game__subtittle {
    font-family: var(--font-raleway-semibold-italic);
    font-size: 1.4rem;
    color: rgba(0, 224, 255, 1);
    margin-top: -5px;
}

.container-game .container-game__details .container-game__desc {
    font-family: var(--font-raleway-regular);
    font-size: 1.4rem;
    height: 160px;
    display: flex;
    align-items: center;
}

.container-game .container-game__details .container-game__platform {
    font-family: var(--font-raleway-extrabold-italic);
    font-size: 1.3rem;
}

.container-game .container-game__details .container-game__link {
    margin-top: auto;
    display: flex;
    gap: 30px;
}