.upload-container {
  display: grid;
  grid-template-columns: 5fr 2fr;
  justify-content: center;
  align-items: center;
  gap: 0px 10px;
  margin: 0;
}

.drop-area {
  grid-row: 1/3;
}

.drop-area,
.upload-instructions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 0;
  border-radius: 8px;
}

.drop-area.todo {
  border-radius: 8px;
  box-shadow: 0px 0px 26px rgba(0, 163, 255, 0.33);
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 28, 32, 1) 100%);
  border: 1px solid rgba(59, 197, 240, 1);
  border-top: 0px;
}

.drop-area.not-approved {
  border-radius: 8px;
  box-shadow: 0px 0px 26px rgba(255, 61, 61, 0.33);
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 28, 32, 1) 100%);
  border: 1px solid rgba(255, 61, 61, 1);
  border-top: 0px;
}


.drop-area.completed {
  border-radius: 8px;
  box-shadow: 0px 0px 26px rgba(57, 255, 113, 0.33);
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 28, 32, 1) 100%);
  border: 1px solid rgba(12, 154, 0, 1);
  border-top: 0px;
}

.drop-area .upload-instructions {
  height: 50px;
}

.drop-area .upload-instructions p {
  width: 98%;
  margin: 0 auto;
  font-family: var(--font-raleway-italic);
  font-size: 0.8125rem;
  color: #fff;
  text-align: center;
}

.image-preview {
  height: 200px;
  max-width: 394px;
  border-radius: 8px;
}