.partners-background {
  display: none;
}

.partners-background2 {
  display: none;
}

.partner-container2 {
  width: 100%;
  top: 0;
}

.partner {
  width: 100%;
  top: 0;
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0px 20px
}

.partner a {
  width: 40%;
  margin: 0 auto;
}

.partner a img {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .partner-container {
    position: relative;
    height: 300px;
  }

  .partners-background {
    position: relative;
    display: flex;
    margin: 0 auto;
    top: 110px;
    width: 100%;
  }

  .partners-background2 {
    position: relative;
    display: block;
    width: 100%;
    top: 100px;
  }

  .partner-container2 {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .partner {
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .partner a {
    margin: auto;
    width: 30%;
    text-align: center;
  }

  .partner a img {
    width: 70%;
  }
}

@media screen and (min-width: 768px) {
  .partner-container {
    height: 380px;
  }

  .partners-background {
    top: 130px;
  }

  .partners-background2 {
    width: 100%;
    top: 145px;
  }

  .partner {
    padding-top: 20px;
  }

  .partner a {
    width: 33%;
  }

  .partner a img {
    width: 65%;
  }
}

@media screen and (min-width: 1024px) {
  .partner-container {
    height: 270px;
    margin: 0 30px;
  }

  .partners-background {
    width: auto;
    top: 20px;
  }

  .partners-background2 {
    width: 100%;
    margin: 0;
    top: 50px;
  }

  .partner-container2 {
    width: 100%;
    top: 0;
  }

  .partner {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .partner a {
    width: 20%;
  }

  .partner a img {
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .partner-container {
    height: 330px;
    margin: 0;
  }

  .partners-background {
    top: 78px;
  }

  .partners-background2 {
    top: 0px;
  }

  .partner-container2 {
    width: 70%;
    margin: 40px auto;
    left: 15%;
  }

  .partner {
    gap: 0px 20px;
    padding-top: 50px;
  }

  .partner a {
    width: auto;
    margin: 0;
  }

  .partner a img {
    width: 100%;
  }
}