.container-signin {
  position: relative;
  display: grid;
  justify-content: center;
}

.container-login {
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;

  & img.logo {
    width: fit-content;
    margin: 0;
    margin-top: 70px;
  }
}

.container-login h2 {
  font-family: var(--font-bebas);
  font-weight: bold;
  font-size: 44px;
}

.container-login form div {
  width: 460px;
  margin: 8px auto;
  text-align: left;
}

.container-login form div label {
  font-family: var(--font-raleway-semibold);
  font-size: 23px;
  display: block;
}

.container-login form div input {
  border-radius: 10px;
  font-family: var(--font-raleway-medium);
  font-size: 20px;
}

.container-signin .bg-login {
  position: relative;
  margin-top: -100px;
  width: 100%;
}