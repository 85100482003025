.container-article section {
  max-width: 30rem;
}

.container-article section a {
  text-decoration: none;
  transition: all 300ms ease 0ms;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-article section a:hover {
  transform: translateY(-10px);
}

.container-article section a img {
  width: 90%;
  margin: 0 5%;
}

.container-article section a h2 {
  width: 82%;
  margin: auto;
  text-align: center;
font-family: var(--font-raleway-regular);
  font-size: 1rem;
  margin: 1rem 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.container-article section a p {
  width: 82%;
  margin: 10px auto;
  text-align: center;
font-family: var(--font-raleway-regular);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (min-width: 425px) {}

@media screen and (min-width: 768px) {
  .container-article section {
    width: 100%;
  }

  .container-article section a img {
    width: 100%;
  }

  .container-article section a h2 {
    width: 90%;
    font-size: 1rem;
  }

  .container-article section a p {
    width: 90%;
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1280px) {
  .container-article section a h2 {
    width: 80%;
    font-size: 1.25rem;
    margin: 1.25rem 0;
  }

  .container-article section a p {
    width: 80%;
    font-size: 1rem;
  }
}