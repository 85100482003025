@import url('./fonts.css');
@import url('./variables.css');

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: var(--color-text);
  height: 100%;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--color-text);
}

hr {
  height: 1px;
  background: var(--color-hr-primary);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}

.button1 {
  display: block;
  text-decoration: none;
  font-family: var(--font-bebas);
  font-size: 35px;
  color: #fff;
  background-color: #00b2ff;
  text-align: center;
  border-radius: 8px;
  border: none;
  margin: 20px auto;
  padding: 0;
}

.btn-large {
  width: 250px !important;
}

.button1:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  background-color: #42c6ff;
  cursor: pointer;
}

.button1:active {
  background-color: #94dfff;
  filter: drop-shadow(0px 8px 30px rgb(148, 223, 255, 0.5));
}

.button1-desactive {
  font-family: var(--font-bebas);
  font-size: 35px;
  color: #fff;
  background-color: #373737;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  border: none;
  margin: 10px auto;
  padding: 0;
}

.btn-form {
  display: block;
  text-decoration: none;
  font-family: var(--font-bebas);
  font-size: 35px;
  color: #fff;
  background-color: #00B2FF;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  border: none;
  margin: 20px auto;
  padding: 0;
}

.btn-form:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  background-color: #42c6ff;
  cursor: pointer;
}

.btn-form:disabled {
  color: #fff;
  background-color: #373737;
  cursor: auto
}

.button2 {
  display: block;
  text-decoration: none;
  font-family: var(--font-bebas);
  font-size: 35px;
  color: #fff;
  background-color: #ffb039;
  text-align: center;
  border-radius: 8px;
  width: 188px;
  border: none;
  margin: 0 auto;
  padding: 0;
}

.button2:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  background-color: #42c6ff;
}


.button2-desactive {
  font-family: var(--font-bebas);
  text-decoration: none;
  font-size: 35px;
  color: #fff;
  background-color: #373737;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  border: none;
  margin: 0px auto;
  padding: 0;
}

.button3 {
  display: block;
  text-decoration: none;
  font-family: var(--font-bebas);
  font-size: 35px;
  color: #fff;
  background-color: #373737;
  text-align: center;
  border-radius: 8px;
  width: 225px;
  border: none;
  margin: 0 auto;
  padding: 0;
}

.button3:hover {
  filter: drop-shadow(0px 8px 15px rgba(0, 224, 255, 0.1));
  background-color: #208CAF;
  cursor: pointer;
}

.button3.active {
  background-color: #00B2FF;
  filter: drop-shadow(0px 8px 15px rgba(0, 224, 255, 0.3));
  cursor: pointer;
}

p .italic {
  font-family: var(--font-raleway-semibold-italic);
}

select {
  appearance: none;
  border-radius: 5px;
  width: 180px;
  height: 40px;
  margin-top: 10px;
  font-family: var(--font-raleway-bold);
  font-size: 18px;
  color: #00e0ff;
  text-align: center;
  border-top: 0px;
  border-color: rgba(0, 149, 182, 0.6);
  background: transparent;
  background-image: url("../public/img/Quest/arrow.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.5em top 50%;
  background-size: auto auto;
}

select.select-mision {
  width: 290px;
}

select::-ms-expand {
  display: none;
}

select:focus {
  color: white;
  background: black;
  background-image: url("../public/img/Quest/arrow.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.5em top 50%;
  background-size: auto auto;
  outline: none;
}

select option {
  font-family: var(--font-raleway-medium);
  margin: 50px 0 !important;
}

.txt-form {
  font-family: var(--font-raleway-italic);
  font-size: 16px;
  color: #ff4a4a;
  margin: 5PX;
}

.txt-login,
.txt-signup {
  font-family: var(--font-raleway-semibold);
  font-size: 16px;
  margin-top: 40px;
}

.txt-password {
  font-family: var(--font-raleway-semibold);
  font-size: 16px;
  margin: -10px 0 30px;
}

.txt-login a,
.txt-signup a,
.txt-password a {
  font-family: var(--font-raleway-semibold-italic);
  color: #00e0ff !important;
  text-decoration: none;
}




.txt-error {
  font-family: var(--font-raleway-italic);
  font-size: 16px;
  color: #ff4a4a;
}


/*Estilos de texto*/
.headline,
.subheadline-lg,
.subheadline-md,
.subheadline-sm {
  width: fit-content;
  margin: 0 auto;
  font-family: var(--font-bebas);
  font-weight: bold;
  text-transform: uppercase;
}

.headline hr {
  height: 1px;
  background: var(--color-hr-secundary);
}

@media only screen and (max-width: 767.9px) {
  .headline {
    font-size: 2.5rem;
  }

  .headline hr {
    width: 160%;
    margin: 0px 0 0 -30%;
  }
}

@media only screen and (min-width: 768px) {
  .headline {
    font-size: 2.8rem;
  }

  .headline hr {
    width: 140%;
    margin: -3px 0 0 -20%;
  }

}

@media only screen and (min-width: 992px) {
  .headline {
    font-size: 3.5rem;
  }

  .headline hr {
    width: 180%;
    margin: -5px 0 0 -40%;
  }

}

@media only screen and (min-width: 1200px) {
  .headline {
    font-size: 4.6875rem;
  }

  .headline hr {
    width: 200%;
    margin: -8px 0 0 -50%;
  }
}

@media only screen and (min-width: 2048px) {
  .headline {
    font-size: 5.2rem;
  }

  .headline hr {
    height: 2px;
    width: 220%;
    margin: -8px 0 0 -60%;
  }
}