header {
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.menu {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 70px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  font-family: var(--font-bebas);
  text-transform: uppercase;
  font-size: 19px;
  letter-spacing: 0.2em;
  text-align: center;
  margin: auto 0;

  & a {
    color: white;
    text-decoration: none;
    padding: 8px 16px;
  }

  & a:hover {
    color: #00e0ff;
  }

  & a.selected {
    color: #00E0FF;
    filter: drop-shadow(0px 0px 40px rgba(0, 224, 255, 1));
    font-size: 20px;
  }
}

.menu-toggle {
  display: none;
}

.menu-icon {
  display: block;
  font-size: 26px;
  color: white;
  cursor: pointer;
}

.menu-toggle:checked+.menu-icon+.menu {
  display: flex;
}

@media (min-width: 768px) {
  .menu {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .menu li {
    margin: auto 10px;
  }

  .menu-icon {
    display: none;
  }
}


.dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0
}

.dropdown-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  margin-top: 10px;
  position: absolute;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 2px 25px 0px rgba(0, 224, 255, 0.5);
  z-index: 1;
  border-radius: 5px;
  right: 0;
  list-style: none;
  padding-left: 0;
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li a {
  color: white;
  padding: 12px 0px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.nav-logo {
  margin-right: 10px;
}

.dropdown-menu hr {
  height: 1px;
  background: var(--color-hr-secundary);
  border: none;
  margin: 0 auto;
  width: 60%;
}


/* Estilo del dropdown */
.dropdown-v1 {
  position: relative;
  display: inline-block;
}

.dropdown-button-v1 {
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-size: 19px;
  letter-spacing: 0.2em;
  padding: 0px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-button-v1:hover,
.dropdown-menu-v1 li a:hover {
  color: #00e0ff;
}

.dropdown-menu-v1 {
  display: none;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  background-image: url("../../../public/img/1.Header/header2.png");
  padding: 10px 0;
  list-style: none;
  box-shadow: 0px 2px 10px rgba(0, 224, 255, 0.5);
  z-index: 2;
  white-space: nowrap;

}

.dropdown-menu-v1 li {
  display: inline-block;
  margin: 0 15px;
}

.dropdown-menu-v1 li a {
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  transition: all 0.3s ease;
}

.dropdown-v1:hover .dropdown-menu-v1 {
  display: flex;
  justify-content: right;
}