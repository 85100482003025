.container-ip {
    margin: 70px 0 0;
    padding: 0;
    color: #fff;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: var(--background-color);
}

.container-ip__details {
    width: 100%;
    height: 506px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-repeat: no-repeat;
}

.container-ip__details img {
    width: auto;
    height: auto;
}

.container-ip__details p {
    width: 300px;
    text-align: center;
}

.continer-games {
    margin: -100px 0 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 30px;
    align-items: flex-start;
}

.continer-games .continer-games__menu{
    justify-self:start
}
