.container-dashboard {
    margin-top: 80px;
    background-color: transparent;
}

.container-dashboard.nft {
    background-image: url("../../../public/img/6.Dashboard/bg_dashboard.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
}

.container-dashboard.inventory {
    background-image: url("../../../public/img/6.Dashboard/bg_inventory.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
}

.hr-dashboard {
    margin-top: -10px;
    height: 1px;
    background: var(--color-hr-secundary);
    border: none;
    width: 40%;
}

.container-nft2 {
    width: 55%;
    margin: auto;

    & .txt-nfts {
        font-family: var(--font-raleway-bold);
        font-size: 18px;
        width: fit-content;
        padding: 5px 30px;
        border-radius: 30px;
        background-color: rgba(0, 224, 255, 0.25);
    }
}