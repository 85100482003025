:root {
    /* Fuentes Bebas Neue */
    --font-bebas: 'Bebas Neue', 'Rockwell condensed', Impact, sans-serif;

    /* Fuentes Raleway */
    --font-raleway-black: 'Raleway Black', 'Helvetica Semibold', 'Arial semibold', sans-serif;
    --font-raleway-thin: 'Raleway Thin', Helvetica, Arial, sans-serif;
    --font-raleway-semibold: 'Raleway Semibold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
    --font-raleway-italic: 'Raleway Italic', 'Helvetica Italic', 'Arial Italic', Arial, sans-serif;
    --font-raleway-medium: 'Raleway Medium', Helvetica, Arial, sans-serif;
    --font-raleway-semibold-italic: 'Raleway Semibold Italic', 'Helvetica Semibold Italic', 'Arial Semibold Italic', sans-serif;
    --font-raleway-regular: 'Raleway Regular', 'Arial Regular', sans-serif;
    --font-raleway-light-italic: 'Raleway Light Italic', 'Helvetica Italic', 'Arial Italic', Arial, sans-serif;
    --font-raleway-bold: 'Raleway Bold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
    --font-raleway-bold-italic: "Raleway Bold Italic", 'Helvetica Italic', 'Arial  Semibold Italic', sans-serif;
    --font-raleway-extrabold: 'Raleway ExtraBold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
    --font-raleway-extrabold-italic: 'Raleway ExtraBold Italic', 'Raleway ExtraBold', 'Helvetica Semibold', 'Arial semibold', sans-serif;

    /* Fuentes Montserrat */
    --font-montserrat-semibold-italic: 'Montserrat Semibold Italic', 'Arial Semibold Italic', sans-serif;
    --font-montserrat-black-italic: 'Montserrat Black Italic', 'Arial Black Italic', sans-serif;
    --font-montserrat-bold-italic: 'Montserrat Bold Italic', 'Arial Bold Italic', sans-serif;

    /*Colors*/
    --color-text: rgba(255, 255, 255, 1);
    --color-text-disabled: rgba(255, 255, 255, 0.8);
    --color-text-important: rgba(250, 255, 0, 1);

    /*Buttons Colors*/
    --color-primary: rgb(0, 178, 255);
    --color-secundary: rgba(55, 55, 55, 1);
    --color-warning: rgba(255, 176, 57, 1);
    --color-success: rgb(1, 208, 60);
    --color-danger: rgba(255, 61, 61, 1);

    --color-hover: rgba(66, 198, 255, 1);
    --color-secundary-hover: rgba(32, 140, 175, 1);
    --color-warning-hover: rgba(255, 189, 90, 1);
    --color-success-hover: rgba(57, 255, 113, 1);
    --color-danger-hover: rgb(248, 83, 83);

    --color-pressed: rgba(148, 223, 255, 1);
    --color-warning-pressed: rgba(255, 212, 147, 1);
    --color-success-pressed: rgb(107, 255, 149);
    --color-danger-pressed: rgb(255, 134, 134);

    --color-primary-ds-30: rgba(0, 224, 255, 0.3);
    --color-primary-ds-50: rgba(0, 224, 255, 0.5);
    --color-warning-ds-30: rgba(255, 138, 0, 0.3);
    --color-warning-ds-50: rgba(255, 153, 0, 0.5);
    --color-success-ds-30: rgba(57, 255, 113, 0.3);
    --color-success-ds-50: rgba(57, 255, 113, 0.5);
    --color-danger-ds-30: rgba(255, 61, 61, 0.3);
    --color-danger-ds-50: rgba(255, 61, 61, 0.5);

    /*line Colors*/
    --color-hr-primary: linear-gradient(90deg, rgba(56, 56, 56, 0) 0%, rgba(126, 126, 126, 1) 50%, rgba(56, 56, 56, 0) 100%);
    --color-hr-secundary: linear-gradient(to right, #38383800, #00e0ff, #38383800);
}