.RedesSociales {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 10% 0;
}

.RedesSociales a .RedesSociales-imgLogo {
  width: 100%;
  transition: all 0.4s ease;
}

.RedesSociales a .RedesSociales-imgLogo:hover{
  width: 120%;  
}

@media screen and (min-width: 576px) {

}

@media screen and (min-width: 768px) {
  .RedesSociales {
    margin: 8% 0;
  } 
}

@media screen and (min-width: 1280px) {
  .RedesSociales {
    gap: 60px;
    margin: 4% 0 5%;
  }
}