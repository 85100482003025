@font-face {
  font-family: "Bebas Neue";
  font-display: swap;
  src: url("/public/fonts/BebasNeue/BebasNeue.otf");
}

@font-face {
  font-family: "Raleway Thin";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Thin.ttf");
}

@font-face {
  font-family: "Raleway Bold";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Bold.ttf");
}

@font-face {
  font-family: "Raleway Black";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Black.ttf");
}

@font-face {
  font-family: "Raleway Bold Italic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-BoldItalic.ttf");
}

@font-face {
  font-family: "Raleway ExtraBold";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-ExtraBold.ttf");
}

@font-face {
  font-family: "Raleway ExtraBold Italic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Semibold";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Raleway Medium";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway Semibold Italic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Regular";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway Italic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Italic.ttf");
}

@font-face {
  font-family: "Raleway Light Italic";
  font-display: swap;
  font-style: italic;
  src: url("/public/fonts/Raleway/Raleway-LightItalic.ttf");
}

@font-face {
  font-family: "Montserrat Semibold Italic";
  font-display: swap;
  src: url("/public/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat Black Italic";
  font-display: swap;
  src: url("/public/fonts/Montserrat/Montserrat-BlackItalic.ttf");
}

@font-face {
  font-family: "Montserrat Bold Italic";
  font-display: swap;
  src: url("/public/fonts/Montserrat/Montserrat-BoldItalic.ttf");
}

@font-face {
  font-family: "Helvetica Semibold";
  font-display: swap;
  font-weight: 600;
  src: local("Helvetica");
}

@font-face {
  font-family: "Arial Semibold";
  font-display: swap;
  font-weight: 600;
  src: local("Arial");
}

@font-face {
  font-family: "Helvetica Italic";
  font-display: swap;
  font-style: italic;
  src: local("Helvetica");
}

@font-face {
  font-family: "Arial Italic";
  font-display: swap;
  font-style: italic;
  src: local("Arial");
}

@font-face {
  font-family: "Helvetica Semibold Italic";
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local("Helvetica");
}

@font-face {
  font-family: "Arial Semibold Italic";
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local("Arial");
}

@font-face {
  font-family: "Arial Regular";
  font-display: swap;
  letter-spacing: 0.3px;
  src: local("Arial");
}

