.responsive-button {
    display: inline-block;
    width: auto;
    height: max-content;
    margin: 20px auto;
    padding: 0;
    border: none;
    border-radius: 8px;

    font-family: var(--font-bebas);
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: var(--color-text);
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    overflow: hidden;
}

.responsive-button a {
    display: block;
    width: 100%;
    text-decoration: none;
    text-align: center;
}


.responsive-button.disabled {
    background-color: var(--color-secundary) !important;
    color: var(--color-text-disabled) !important;
    box-shadow: 0 8px 30px transparent!important;
    cursor: not-allowed;
}

.responsive-button.disabled:hover {
    box-shadow: 0 8px 30px transparent!important;
}

.responsive-button.primary {
    background-color: var(--color-primary);
    box-shadow: 0 8px 30px var(--color-primary-ds-30);
}

.responsive-button.primary:hover {
    background-color: var(--color-hover);
    box-shadow: 0 8px 30px var(--color-primary-ds-50);
}

.responsive-button.primary.active {
    background-color: var(--color-pressed);
    box-shadow: 0 8px 30px var(--color-primary-ds-50);
}

.responsive-button.secondary {
    background-color: var(--color-secundary);
}

.responsive-button.secondary:hover {
    background-color: var(--color-secundary-hover);
    box-shadow: 0 8px 30px var(--color-primary-ds-30);
}

.responsive-button.secondary.active {
    background-color: var(--color-primary);
    box-shadow: 0 8px 30px var(--color-primary-ds-30);
}

.responsive-button.warning {
    background-color: var(--color-warning);
    box-shadow: 0 8px 30px var(--color-warning-ds-30);
}

.responsive-button.warning:hover {
    background-color: var(--color-warning);
    box-shadow: 0 8px 30px var(--color-warning-ds-50);
}

.responsive-button.warning.active {
    background-color: var(--color-warning-pressed);
}


.responsive-button.success {
    background-color: var(--color-success);
    box-shadow: 0 8px 30px var(--color-success-ds-30);
}

.responsive-button.success:hover {
    background-color: var(--color-success);
    box-shadow: 0 8px 30px var(--color-success-ds-50);
}

.responsive-button.success.active {
    background-color: var(--color-success-pressed);
}

.responsive-button.danger {
    background-color: var(--color-danger);
    box-shadow: 0 8px 30px var(--color-danger-ds-30);
}

.responsive-button.danger:hover {
    background-color: var(--color-danger);
    box-shadow: 0 8px 30px var(--color-danger-ds-50);
}

.responsive-button.danger.active {
    background-color: var(--color-danger-pressed);
}


@media only screen and (max-width: 767.9px) {
    .responsive-button.btn-md {
        padding: 4px 10px;
        font-size: 1.6rem;
        max-width: 12.5rem;
    }
    .responsive-button.btn-xsm {
        padding: 3px 8px;
        font-size: 0.8rem;
        max-width: 5rem;
    }

    .responsive-button.btn-sm {
        padding: 3px 8px;
        font-size: 1.2rem;
        max-width: 7rem;
    }

    .responsive-button.btn-lg {
        padding: 6px 13px;
        font-size: 1.9rem;
        max-width: 15rem;
    }

}

@media only screen and (min-width: 768px) {
    .responsive-button.btn-md {
        padding: 5px 15px;
        font-size: 1.9rem;
    }

    .responsive-button.btn-xsm {
        padding: 3px 8px;
        font-size: 0.9rem;
        max-width: 7rem;
    }

    .responsive-button.btn-sm {
        padding: 6px 10px;
        font-size: 1.3rem;
        max-width: 7.25rem;
    }

    .responsive-button.btn-lg {
        padding: 8px 18px;
        font-size: 2.5rem;
        max-width: 15.25rem;
    }
}

@media only screen and (min-width: 992px) {
    .responsive-button.btn-md {
        padding: 7px 20px;
        font-size: 1.8rem;
    }

    .responsive-button.btn-xsm {
        padding: 3px 8px;
        font-size: 1rem;
        max-width: 8rem;
    }

    .responsive-button.btn-sm {
        padding: 7px 18px;
        font-size: 1.25rem;
        max-width: 9rem;
    }

    .responsive-button.btn-lg {
        padding: 9px 25px;
        font-size: 2.5rem;
        max-width: 20rem;
    }
}

@media only screen and (min-width: 1200px) {
    .responsive-button.btn-md {
        padding: 9px 38px;
        font-size: 2.1875rem;
    }
    .responsive-button.btn-xsm {
        padding: 6px 17px;
        font-size: 1rem;
        max-width: 9rem;
    }

    .responsive-button.btn-sm {
        padding: 7px 30px;
        font-size: 1.75rem;
        max-width: 12.625rem;
    }

    .responsive-button.btn-lg {
        padding: 8px 15px;
        font-size: 3.1rem;
        max-width: 25rem;
    }
}

@media only screen and (min-width: 2048px) {
    .responsive-button.btn-md {
        padding: 8px 60px;
        font-size: 3.5rem;
    }

    .responsive-button.btn-xsm {
        padding: 7px 25px;
        font-size: 1.2rem;
        max-width: 10rem;
    }

    .responsive-button.btn-sm {
        padding: 0px 40px;
        font-size: 2rem;
        max-width: 15rem;
    }

    .responsive-button.btn-lg {
        padding: 10px 20px;
        font-size: 4rem;
        max-width: 35rem;
    }
}