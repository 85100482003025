.container-quests {
  width: 100%;
  margin: -10px auto 30px;
  background-image: url("../../../public/img/Quest/bg-quests.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center top;
}

.container-quests .container-quests-filter {
  width: 80%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 4fr 1fr 2fr 1fr 1fr 1fr;
  gap: 0 20px;
  align-items: start;
  justify-content: space-between;

  & .title-quest {
    font-family: var(--font-bebas);
    font-size: 60px;
    margin: 0;

    & hr {
      background: rgb(0, 224, 255);
      background: linear-gradient(90deg,
          rgba(0, 224, 255, 1) 0%,
          rgba(56, 56, 56, 0) 100%);
      width: 100%;
      height: 1px;
      margin: 0;
    }
  }

  & .txt-filter {
    font-family: var(--font-raleway-extrabold-italic);
    color: #00E0FF;
    font-size: 16px;
    margin: 40px 0 0;
  }

  & label {
    font-family: var(--font-raleway-bold);
    font-size: 20px;
    margin: 40px 0 0;
  }
}

.container-quests .container-listQuest {
  width: 80%;
  margin: 40px auto 0px;
  padding: 0 20px 40px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 40px;
  justify-content: center;
  align-items: start;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container-quests .container-listQuest::-webkit-scrollbar {
  width: 5px;
  height: 136px;
}

.container-quests .container-listQuest::-webkit-scrollbar-track {
  background: transparent;
}

.container-quests .container-listQuest::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 6px;
}