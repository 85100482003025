.container-SignUp {
  color: #fff;
  text-align: center;
}

.container-SignUp h2 {
  font-family: var(--font-bebas);
  font-weight: bold;
  font-size: 44px;
}

.container-SignUp img {
  width: fit-content;
  margin: 0;
  margin-top: 70px;
}

.container-SignUp form .container-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  width: 950px;
  margin: 0px auto 36px;
  gap: 20px 50px;
}

.container-SignUp form .container-inputs div {
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

.container-SignUp form .container-inputs div label {
  font-family: var(--font-raleway-semibold);
  font-size: 23px;
  display: block;
}

.container-SignUp form .container-inputs div input {
  width: 100%;
  border-radius: 10px;
  font-family: var(--font-raleway-medium);
  font-size: 20px;
  padding: 5px;
  height: 40px;
}