table {
    width: 100%;
    border-collapse: collapse;
    margin: 30px auto;
    position: relative;
    border: 1px solid rgba(0, 224, 255, 0.7);

    & td {
        border: 1px solid #ccc;
        text-align: center;
        width: 95px;
        height: 95px;
        padding: 0;
        margin: 0 auto;

        & div {
            position: relative;
            display: inline-block;

            & img {
                width: 104px;
                height: 104px;
            }

            & p {
                position: absolute;
                bottom: 5px;
                right: 5px;
                color: #00E0FF;
                font-family: var(--font-raleway-semibold);
                font-size: 14px;
                margin: 0;
                padding: 0;
            }
        }
    }

    td.td-item {
        background-color: rgba(0, 178, 255, 0.1);
    }

    td.td-item:hover {
        background: radial-gradient(circle, rgba(55, 219, 255, 0.3) 0%, rgba(0, 82, 117, 0.3) 100%);
    }

    td.hover2:hover {
        background: transparent;
    }

    td.hover2 {
        background: radial-gradient(circle, rgba(55, 219, 255, 0.3) 0%, rgba(0, 82, 117, 0.3) 100%);
    }

    .txt-item {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .comic-bubble {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 2px;
        background-color: #000000;
        border: 1px solid rgba(0, 149, 182, 1);
        border-radius: 5px;
        width: 84px;
        text-align: center;
        color: #fff;
        z-index: 5;
        font-family: var(--font-montserrat-semibold-italic);
        font-size: 12px;
    }

    .comic-bubble::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 8px solid black;
        border-top-color: rgba(0, 149, 182, 1);
        border-radius: 2px;
    }

    th,
    td {
        border: 1px solid rgba(0, 224, 255, 0.7);
        border-spacing: 0;
    }

}