article {
  color: #fff;
}

article section.containertext-ashara{
  background-image: url("../../../public/img/3.Home/backgroundAlas.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size:auto;
}


article section p.text-ashara {
  font-family: var(--font-raleway-regular);
  font-size: 1rem;
  text-align: center;
  margin: 1% 5% 0%;
  padding:2.8% 0;
}

.container-article {
  margin: auto;
  width: 80%;
  justify-content: center;
  align-items: flex-start;
}

.container-socialNetwork {
  text-align: center;
  padding-top: 30px;
}

.socialNetwork-followUs {
  font-family: var(--font-raleway-regular);
  font-size: 2rem;
  margin: 0;
  color: #fff;
  text-align: center;
}

.container-heroes {
  text-align: center;
  color: #fff;
}

.btn-flotante {
  font-family: var(--font-raleway-regular);
  font-weight: bold;
  color: #ffffff;
  border-radius: 50px;
  background-color: #5865f2;
  padding: 12px 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.btn-flotante:hover {
  background-color: #202225;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

.btn-img {
  width: 40px;
}

.title-partner {
  font-family: var(--font-bebas);
  text-transform: uppercase;
  font-size: 40px;
  width: fit-content;
  padding-top: 30px;
  margin: 20px auto 0;
}

.title-partner+hr {
  height: 1px;
  background: var(--color-hr-secundary);
  border: none;
  margin-top: -5px;
  width: 60%;
}

@media screen and (min-width: 425px) {
  article section p.text-ashara {
    font-size: 1.2rem;
    margin: 2% 10% 0%;
  }

  .title-partner {
    font-size: 72px;
  }

  .title-partner+hr,
  .title-season+hr {
    margin-top: -10px;
    width: 60%;
  }

}

@media screen and (min-width: 768px) {
  article section p.text-ashara {
    font-size: 1.25rem;
    text-align: center;
    margin: 3% 6% 0;
  }

  .container-article {
    display: flex;
    margin: auto;
    width: 85%;
    gap: 0 10px;
    margin-bottom: 30px;
  }

  .title-partner {
    font-size: 96px;
  }

  .title-partner+hr {
    width: 35%;
  }
}

@media screen and (min-width: 1280px) {
  article section p.text-ashara {
    font-size: 1.7rem;
    text-align: center;
  }

  .container-article {
    width:75%;
    gap: 0 20px;
  }

  .title-partner {
    font-size: 75px;
  }
}