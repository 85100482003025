.container-preregister {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 850px;
    margin-top: -120px;
    background:
        url('../../../../public/img/AsharaSeason/1.PreRegister/cofre.webp') center / 80% no-repeat,
        url('../../../../public/img/AsharaSeason/1.PreRegister/bg_ashara_coins.webp') center / 80% no-repeat;
}

.container-preregister .preregister__number {
    width: 100%;
    margin: 0;
    margin-top: 200px;
    padding: 0;

    font-family: var(--font-montserrat-black-italic);
    text-transform: uppercase;
    text-align: center;
    font-size: 172px;
    color: white;

    background-color: white;
    background-image: linear-gradient(180deg, rgba(255, 199, 0, 0) 50%, rgba(255, 199, 0, 0.22) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 0 3px rgba(255, 199, 0, 1)) drop-shadow(0 0 3px rgba(255, 199, 0, 1));
}

.container-preregister .preresgister__sub {
    width: 100%;
    margin: -35px 0 0 0;
    padding: 0;

    font-family: var(--font-montserrat-bold-italic);
    text-align: center;
    font-size: 58px;
}

.container-preregister .preregister__desc {
    font-family: var(--font-raleway-medium);
    text-align: center;
    font-size: 22px;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 40px;
}

.container-preregister .preregister__desc span {
    font-family: var(--font-raleway-bold);
    color: var(--color-text-important);
}

.container-preregister .preregister__desc2 {
    font-family: var(--font-raleway-bold-italic);
    font-size: 22px;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
}


.container-preregister form div {
    width: 460px;
    margin: 6px auto 4px;
    text-align: left;
}

.container-preregister form div label {
    font-family: var(--font-raleway-semibold);
    font-size: 23px;
    display: block;
}

.container-preregister form div input {
    border-radius: 10px;
    font-family: var(--font-raleway-medium);
    font-size: 20px;
}


@media only screen and (max-width: 767.9px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 2048px) {
    .container-preregister {
        height: 75rem;
        margin-top: -10rem;
        background-size: 80%, 80%;
    }

    .container-preregister .preregister__number {
        margin-top: 18.75rem;
        font-size: 16.25rem;
    }

    .container-preregister .preresgister__sub {
        margin-top: -3.5rem;
        font-size: 6rem;
    }

    .container-preregister .preregister__desc {
        font-size: 2.5rem;
        width: 100%;
        margin-top: 3.75rem;
    }

    .container-preregister .preregister__desc2 {
        font-size: 2.5rem;
    }

    .container-preregister form div {
        width: 31.25rem;
        margin: 15px auto 10px;
        text-align: left;
    }

    .container-preregister form div label {
        font-size: 2rem;
    }

    .container-preregister form div input {
        font-size: 1.7rem;
        padding: 6px inherit;
    }
}