article.container-season {
  margin-top: 50px;
}

article section p.text-season {
  font-family: var(--font-raleway-regular);
  font-size: 1.5rem;
  text-align: center;
  margin: 3% 5%;
}

.season-menu {
  display: flex;
  width: 30%;
  margin: 0 auto
}


.container-infoPass {
  margin-top: 30px;
}

.container-sandPool {
  margin-top: 30px;
  text-align: center;
  color: #fff;
  background-image: url("/public/img/AsharaSeason/ashara_studios_blanco_BG.webp");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;
}

.container-barProgress {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 100px 0px 50px;

}

.container-barProgress+hr {
  position: absolute;
  width: 420px;
  left: 47.6%;
  margin-top: -127px;
  transform: translateX(-50%);
  background: rgb(0, 224, 255);
  background: linear-gradient(90deg,
      rgba(0, 224, 255, 0.14) 14%,
      rgba(0, 224, 255, 0.8) 73%);
  z-index: 1;

}

.container-barProgress .info-week {
  font-family: var(--font-raleway-semibold-italic);
  margin: 0;
  position: absolute;
  text-align: center;
  font-size: 30px;
  margin-left: -520px;
  top: -33%
}

.container-barProgress .initial-bar {
  position: absolute;
  margin-left: -425px;
  margin-top: 10px;
  z-index: 1;
}

.container-barProgress .container-barProgress2 {
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 100px;
  width: 440px;
  background-image: url("/public/img/AsharaSeason/Bar/BG.png");
  background-repeat: no-repeat;
  background-position: 10px -10px;
  background-size: 98%;
}

.container-reward {
  position: relative;
  margin-left: 15px;
  margin-top: -70px;
}

.container-reward .backgorund-reward {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container-reward .coin-reward {
  position: relative;
  z-index: 1;
}

.container-reward p {
  font-family: var(--font-raleway-bold);
  color: #ffd540;
  font-size: 21px;
  text-align: left;
  filter: drop-shadow(0px 0px 9px rgb(255, 199, 0, 0.89));
  margin-top: -35px;
  position: relative;
  margin-left: 40px;
  z-index: 1;
}

.container-infoReward {
  position: relative;
  width: 100%;
  margin: 0 auto 50px;
}

.container-infoReward p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--font-raleway-bold);
  font-size: 24px;
  color: #fff;
  width: 50%;
  text-align: center;
  margin: auto;
}

.container-infoReward .chest-infoReward {
  position: absolute;
  right: 60;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 20.7px rgba(255, 199, 0, 0.14));
}

.container-infoReward p span {
  color: #ffd540;
}

.container-info-benefits {
  position: relative;
}

.container-info-benefits .backgorund-info-benefits {
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  transform: translate(-34%, 10%);
}

.container-info {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 74px;
}

.container-infoSeason {
  display: flex;
  width: 75%;
  margin: 0 auto;
  justify-content: center;
  gap: 0 30px;
  text-align: center;
  position: relative;
  top: 0;
  padding: 50px 0;
  border-radius: 10px;
  z-index: 0;
  border: solid rgba(0, 100, 113, 0.8) 1px;
}

.container-infoSeason::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(217, 217, 217, 1) 0%, rgba(169, 169, 169, 1) 50%, rgba(115, 115, 115, 0) 100%);
  opacity: 0.1;
  z-index: -1;
  border-radius: 10px;
}

.container-infoSeason .subtitle-season {
  font-family: var(--font-bebas);
  font-weight: bold;
  font-size: 47px;
  padding: 25px 0 0 0;
  margin: 0;
}

.container-infoSeason ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container-infoSeason .container-benefits {
  width: 100%;
}

.container-infoSeason .container-benefits ul li {
  text-align: left;
  margin-left: 55px;
  font-family: var(--font-raleway-semibold);
  font-size: 18px;
  margin-top: 39px;
}

.container-infoSeason .container-benefits ul li+hr {
  background: rgb(0, 224, 255);
  background: linear-gradient(90deg,
      rgba(0, 224, 255, 0) 0%,
      rgba(0, 224, 255, 1) 100%);
  margin: 29px 0 0 0;
  height: 1px;
}

.container-infoSeason .element-free {
  width: 100%;
  position: relative;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 60px rgba(0, 54, 135, 0.4));
}

.container-infoSeason .element-free::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.5px solid transparent;
  background: linear-gradient(29deg,
      rgba(59, 191, 240, 1) 0%,
      rgba(0, 39, 56, 0.15) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 12px;
}

.container-infoSeason .content-free {
  border-radius: 12px;
  background-color: rgba(0, 15, 38, 0.6);
  backdrop-filter: blur(17.9px);
  position: relative;
  z-index: 1;
  height: 100%;
}

.container-infoSeason .content-free ul li,
.container-infoSeason .content-pass ul li {
  font-family: var(--font-raleway-regular);
  font-size: 18px;
  text-align: center;
  margin-top: 39px;
  position: relative;
}

.container-infoSeason .content-free ul li hr {
  width: 80%;
  background: rgb(56, 56, 56);
  background: linear-gradient(90deg,
      rgba(56, 56, 56, 0) 0%,
      rgba(0, 224, 255, 1) 50%,
      rgba(56, 56, 56, 0) 100%);
  margin: 29px auto 0 auto;
}

.container-infoSeason .element-pass {
  width: 100%;
  position: relative;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 60px rgba(158, 0, 255, 0.4));
}

.container-infoSeason .element-pass::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1.5px solid transparent;
  background: rgb(158, 0, 255);
  background: linear-gradient(90deg,
      rgba(158, 0, 255, 1) 0%,
      rgba(0, 255, 255, 0.15) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 12px;
}

.container-infoSeason .content-pass {
  border-radius: 12px;
  background-color: rgba(21, 0, 41, 0.6);
  backdrop-filter: blur(17.9px);
  position: relative;
  z-index: 1;
  height: 100%;
}

.container-infoSeason .content-pass .subtitle-season {
  padding: 25px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px 16px;
}

.container-infoSeason .content-pass .subtitle-season h2 {
  font-family: var(--font-bebas);
  font-weight: bold;
  font-size: 47px;
  margin: 0;
  padding: 0;
}

@keyframes fadeRotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.container-infoSeason .content-pass .subtitle-season .subtitle-start {
  filter: drop-shadow(0px 0px 11.2px rgba(255, 176, 57, 0.77));
  margin: 0;
  animation: fadeRotate 5s infinite linear;

}

.container-infoSeason .content-pass ul li hr {
  width: 80%;
  background: rgb(56, 56, 56);
  background: linear-gradient(90deg,
      rgba(56, 56, 56, 0) 0%,
      rgba(156, 95, 255, 1) 50%,
      rgba(56, 56, 56, 0) 100%);
  margin: 29px auto 0 auto;
}

.shadow {
  position: absolute;
  left: 0;
  bottom: 0;
}

.container-buy {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 25%;
  gap: 10px 5px;
  z-index: 2;
  bottom: -4%;
  right: 12.0%;
  align-items: center;
  justify-content: center;
}

.max-width {
  width: 25%;
  justify-content: center;
}

.container-buy .button2,
.container-buy .button2-desactive {
  width: auto;
  margin: 0;
  padding: 0 10px;
}

.container-buy .button2-desactive {
  position: relative;
  cursor: not-allowed;
}

.container-buy .tooltip {
  font-family: var(--font-montserrat-semibold-italic);
  font-size: 12px;
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 149, 182, 1);
  font-size: 14px;
  margin: -27px 0 0 -25px;
  z-index: 10;
  width: auto
}

.container-buyPopUp h2+hr {
  margin: 0 auto 100px !important;
}

.container-buyPopUp .txt-popUp2 {
  font-family: var(--font-montserrat-bold-italic);
  font-size: 23px;
  text-align: center;
}

.container-buyPopUp .txt-subtitle2 {
  font-family: var(--font-montserrat-bold-italic);
  font-size: 20px;
  text-align: center;
  color: var(--color-text-important);
}


.container-buyPopUp .txt-link {
  font-family: var(--font-raleway-semibold-italic);
  color: rgba(0, 224, 255, 1);
  font-size: 15px;
  margin: 100px 0;
}


.container-buyPopUp .txt-link:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  cursor: pointer;
}

.float-turtle {
  position: absolute;
  width: fit-content;
  z-index: 0;
  bottom: -80%;
  right: 0;
  filter: drop-shadow(0px 0px 60px rgba(158, 0, 255, 0.51));
}

@media only screen and (max-width: 767.9px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 2048px) {}